
import axios, { CancelTokenSource } from 'axios';
import { defineComponent } from 'vue';
import TextField from '@/components/controls/TextField.vue';
import DataItem from '@/components/layout/DataItem.vue';
import Button from '@/components/controls/Button';

interface Data {
    isLoading: boolean;
    records: never[];
    searchTerm: string;
    values: never[];
    cancelToken: CancelTokenSource | null;
    cancelTokenMultiple: CancelTokenSource | null;
    expanded: boolean;
}

export default defineComponent({
    name: 'SelectMultipleReference',
    components: {
        Button,
        DataItem,
        TextField,
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        service: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: (): string[] => [],
        },
        createRoute: {
            type: Object,
            default: () => null,
        },
        imageProperty: {
            type: String,
            default: null,
        },
        orderBy: {
            type: String,
            default: 'name',
        },
        labelProperty: {
            type: String,
            default: 'name',
        },
    },
    data: (): Data => ({
        isLoading: false,
        records: [],
        values: [],
        searchTerm: '',
        cancelToken: null,
        cancelTokenMultiple: null,
        expanded: false,
    }),
    watch: {
        modelValue(): void {
            this.loadValues();
        },
        expanded(): void {
            if (this.expanded) {
                this.loadRecords();
            }
        },
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                'select-multiple-reference--expanded': this.expanded,
                'select-multiple-reference--error': this.errors.length > 0,
            };
        },
        modelValueLabels(): string[] {
            return this.values.map((value) => (value as never)[this.labelProperty]);
        },
        filteredRecors(): never[] {
            return (this.records as never[]).filter(({ id }) => !this.modelValue.includes(id as string));
        },
    },
    methods: {
        handleValueClicked(): void {
            this.expanded = !this.expanded;
        },
        addValue(value: string) {
            this.emitInput([
                ...new Set([
                    ...this.modelValue as string[],
                    value,
                ]),
            ]);
        },
        removeValue(value: string) {
            this.emitInput((this.modelValue as string[]).filter((fValue) => fValue !== value));
        },
        emitInput(values: string[]): void {
            this.$emit('update:modelValue', values);
        },
        async loadRecords(): Promise<void> {
            if (this.cancelTokenMultiple) {
                this.cancelTokenMultiple.cancel();
            }
            this.cancelTokenMultiple = axios.CancelToken.source();
            this.isLoading = true;
            try {
                const response = await this.service.getMultiple({
                    pageSize: 15,
                    searchQuery: this.searchTerm,
                    orderBy: `${this.orderBy} asc`,
                }, this.cancelTokenMultiple);
                this.records = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadValues(): Promise<void> {
            this.values = [];
            if (!this.modelValue) {
                return;
            }
            if (this.cancelToken) {
                this.cancelToken.cancel();
            }
            this.cancelToken = axios.CancelToken.source();
            this.isLoading = true;
            try {
                const response = await this.service.getMultipleByIds(this.modelValue, null, this.cancelToken);
                this.values = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
});
