
import { defineComponent } from 'vue';
import ShipProperty from '@/components/constructions/ship/ShipProperty.vue';
import { Ship } from '@/interfaces/constructions/ship';
import { Material } from '@/interfaces/ingame/material';
import { PublicUser } from '@/interfaces/social/publicUser';
import { Company } from '@/interfaces/social/company';

export default defineComponent({
    name: 'ShipInfoMatrix',
    components: {
        ShipProperty,
    },
    props: {
        ship: {
            type: Object,
            default: () => null,
        },
        armorMaterial: {
            type: Object,
            default: () => null,
        },
        creator: {
            type: Object,
            default: () => null,
        },
        company: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        typedShip(): Ship | null{
            return this.ship as Ship | null;
        },
        typedArmorMaterial(): Material | null {
            return this.armorMaterial as Material | null;
        },
        typedCreator(): PublicUser | null {
            return this.creator as PublicUser | null;
        },
        typedCompany(): Company | null {
            return this.company as Company | null;
        },
    },
});
