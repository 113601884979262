
import { defineComponent } from 'vue';
import FileExplorer, { FileSelection } from '@/components/cdn/FileExplorer.vue';
import Dialog from '@/components/dialogs/Dialog.vue';

export default defineComponent({
    name: 'SelectFiles',
    components: {
        FileExplorer,
        Dialog,
    },
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String,
            default: null,
        },
        modelValue: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        dialogShown: false,
        selectedFiles: [],
    }),
    computed: {
        values(): string[] {
            return this.modelValue ? this.modelValue.split(',') : [];
        },
        selectedFileStyles(): Record<string, string>[] {
            return this.values.map((value) => ({
                backgroundImage: `url('${value}')`,
            }));
        },
    },
    methods: {
        showFileBrowser(): void {
            this.showDialog();
        },
        removeSelectedFile(selectedFile: string): void {
            if (this.modelValue) {
                this.$emit('update:modelValue', this.values.filter((value) => value !== selectedFile).join(','));
            }
        },
        showDialog(): void {
            this.dialogShown = true;
        },
        hideDialog(): void {
            this.dialogShown = false;
        },
        handleFileSelected(file: FileSelection): void {
            this.dialogShown = false;
            this.$emit('update:modelValue', this.modelValue ? `${this.modelValue},${file.publicUri}` : file.publicUri);
        },
    },
});
